<script setup>
    const props = defineProps({
    question: String,
    opened: {
        type: Boolean,
        default: false
    }
    });
    const isOpened = ref(props.opened);
</script>

<template>
    <div class="p-6 bg-cool-gray-light" :class="{'rounded-lg border border-azure':isOpened}">
        <div class="grid grid-cols-12 items-center cursor-pointer select-none" @click="isOpened = !isOpened">
            <div class="col-span-10 grow font-normal text-coolGray-700 text-lg">{{ question }}</div>
            <div class="col-span-2 w-full justify-end flex">
                <div :class="[{'bg-azure':isOpened}]" class="transition-all w-10 h-10 rounded-full shadow-box flex items-center justify-center">
                <IconChevronDown class="w-6 h-6 -rotate-90" :class="[{'rotate-0  fill-white':isOpened}]" />
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12">
            <div v-show="isOpened" class="col-span-11 font-extralight pt-4 text-coolGray-700">
                <slot />
            </div>
        </div>
    </div>
</template>
